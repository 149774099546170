import '../../app/css/plugins/charts/chart-apex.css';
import '../../app/vendors/css/charts/apexcharts.css';
import ApexCharts from 'apexcharts';

function onMonthYearChange_43c6855e() {
    let url = $('#financial-helper').data('url');

    url = url.replace('year', $('#year').val());
    url = url.replace('month', $('#month').val());

    window.location.href = url;
}

export function initFinancialReportPage() {
    window.ApexCharts = ApexCharts;

    let year = $('#year'), month = $('#month');
    let financialHelper = $('#financial-helper');

    $.ajax({
        url: financialHelper.data('revenue-comparison'),
        type: "post",
        async: true,
        data: 'year=' + year.val() + '&month=' + month.val()
    }).done(function (response) {
        let data = response;
        let yearlyRevenueChart;
        let revenueReportChartOptions = {
            chart: {
                height: 300,
                stacked: true,
                type: 'bar',
                toolbar: {show: false}
            },
            plotOptions: {
                bar: {
                    columnWidth: '17%',
                    borderRadius: 0
                },
                distributed: true
            },
            colors: [window.colors.solid.primary, window.colors.solid.warning],
            series: [
                {
                    name: financialHelper.data('income'),
                    data: data.income
                },
                {
                    name: financialHelper.data('expense'),
                    data: data.expense
                }
            ],
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            grid: {
                padding: {
                    top: -20,
                    bottom: -10
                }
            },
            xaxis: {
                categories: data.months,
                labels: {
                    style: {
                        colors: '#b9b9c3',
                        fontSize: '0.86rem'
                    }
                },
                axisTicks: {
                    show: false
                },
                axisBorder: {
                    show: false
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: '#b9b9c3',
                        fontSize: '0.86rem'
                    },
                    formatter: function (val) {
                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                }
            }
        };

        yearlyRevenueChart = new ApexCharts(document.querySelector('#revenue-report-chart'), revenueReportChartOptions);
        yearlyRevenueChart.render().then();

        $('#revenue-report-chart').data('chart', yearlyRevenueChart);
    });

    let goalChart;
    let goalChartOptions = {
        chart: {
            height: 245,
            type: 'radialBar',
            sparkline: {
                enabled: true
            },
            dropShadow: {
                enabled: true,
                blur: 3,
                left: 1,
                top: 1,
                opacity: 0.1
            }
        },
        colors: ['#51e5a8'],
        plotOptions: {
            radialBar: {
                offsetY: -10,
                startAngle: -150,
                endAngle: 150,
                hollow: {
                    size: '77%'
                },
                track: {
                    background: '#ebe9f1',
                    strokeWidth: '50%'
                },
                dataLabels: {
                    name: {
                        show: false
                    },
                    value: {
                        color: '#5e5873',
                        fontSize: '2.86rem',
                        fontWeight: '600'
                    }
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.65,
                gradientToColors: [window.colors.solid.success],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
            }
        },
        series: [financialHelper.data('income-medal')],
        stroke: {
            lineCap: 'round'
        },
        grid: {
            padding: {
                bottom: 30
            }
        }
    };

    goalChart = new ApexCharts(document.querySelector('#goal-overview-chart'), goalChartOptions);
    goalChart.render().then();

    let profitChart;
    let profitChartOptions = {
        chart: {
            height: 245,
            type: 'radialBar',
            sparkline: {
                enabled: true
            },
            dropShadow: {
                enabled: true,
                blur: 3,
                left: 1,
                top: 1,
                opacity: 0.1
            }
        },
        colors: ['#51e5a8'],
        plotOptions: {
            radialBar: {
                offsetY: -10,
                startAngle: -150,
                endAngle: 150,
                hollow: {
                    size: '77%'
                },
                track: {
                    background: '#ebe9f1',
                    strokeWidth: '50%'
                },
                dataLabels: {
                    name: {
                        show: false
                    },
                    value: {
                        color: '#5e5873',
                        fontSize: '2.86rem',
                        fontWeight: '600'
                    }
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.65,
                gradientToColors: [window.colors.solid.success],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
            }
        },
        series: [financialHelper.data('profit-medal')],
        stroke: {
            lineCap: 'round'
        },
        grid: {
            padding: {
                bottom: 30
            }
        }
    };

    profitChart = new ApexCharts(document.querySelector('#profit-overview-chart'), profitChartOptions);
    profitChart.render().then();

    let orderOverviewChart;
    let orderOverviewChartOptions = {
        chart: {
            height: 245,
            type: 'radialBar',
            sparkline: {
                enabled: true
            },
            dropShadow: {
                enabled: true,
                blur: 3,
                left: 1,
                top: 1,
                opacity: 0.1
            }
        },
        colors: ['#51e5a8'],
        plotOptions: {
            radialBar: {
                offsetY: -10,
                startAngle: -150,
                endAngle: 150,
                hollow: {
                    size: '77%'
                },
                track: {
                    background: '#ebe9f1',
                    strokeWidth: '50%'
                },
                dataLabels: {
                    name: {
                        show: false
                    },
                    value: {
                        color: '#5e5873',
                        fontSize: '2.86rem',
                        fontWeight: '600'
                    }
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.65,
                gradientToColors: [window.colors.solid.success],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
            }
        },
        series: [financialHelper.data('order-medal')],
        stroke: {
            lineCap: 'round'
        },
        grid: {
            padding: {
                bottom: 30
            }
        }
    };

    orderOverviewChart = new ApexCharts(document.querySelector('#order-overview-chart'), orderOverviewChartOptions);
    orderOverviewChart.render().then();

    $.ajax({
        url: financialHelper.data('monthly-orders-chart'),
        type: "post",
        async: true,
        data: 'year=' + year.val() + '&month=' + month.val()
    }).done(function (response) {
        let data = response;
        let revenueChart;
        let revenueChartOptions = {
            chart: {
                height: 240,
                toolbar: {show: false},
                zoom: {enabled: false},
                type: 'line',
                offsetX: -10
            },
            stroke: {
                curve: 'smooth',
                dashArray: [0, 12],
                width: [4, 3]
            },
            legend: {
                show: true
            },
            colors: ['#d0ccff', '#ebe9f1'],
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    inverseColors: false,
                    gradientToColors: [window.colors.solid.primary, '#ebe9f1'],
                    shadeIntensity: 1,
                    type: 'horizontal',
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100, 100, 100]
                }
            },
            markers: {
                size: 0,
                hover: {
                    size: 5
                }
            },
            xaxis: {
                labels: {
                    style: {
                        colors: '#b9b9c3',
                        fontSize: '1rem'
                    }
                },
                axisTicks: {
                    show: false
                },
                categories: data.days,
                axisBorder: {
                    show: false
                },
                tickPlacement: 'on'
            },
            yaxis: {
                tickAmount: 5,
                labels: {
                    style: {
                        colors: '#b9b9c3',
                        fontSize: '1rem'
                    },
                    formatter: function (val) {
                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                }
            },
            grid: {
                borderColor: '#e7eef7',
                padding: {
                    top: -20,
                    bottom: -10,
                    left: 20
                }
            },
            tooltip: {
                x: {show: false}
            },
            series: [
                {
                    name: financialHelper.data('current-month'),
                    data: data.monthly
                },
                {
                    name: financialHelper.data('previous-month'),
                    data: data.previous
                }
            ]
        };

        revenueChart = new ApexCharts(document.querySelector('#monthly-chart-orders'), revenueChartOptions);
        revenueChart.render().then();

        $('#monthly-chart-orders').data('chart', revenueChart);
    });

    $.ajax({
        url: financialHelper.data('monthly-invoices-chart'),
        type: "post",
        async: true,
        data: 'year=' + year.val() + '&month=' + month.val()
    }).done(function (response) {
        let data = response;
        let revenueChart;
        let revenueChartOptions = {
            chart: {
                height: 240,
                toolbar: {show: false},
                zoom: {enabled: false},
                type: 'line',
                offsetX: -10
            },
            stroke: {
                curve: 'smooth',
                dashArray: [0, 12],
                width: [4, 3]
            },
            legend: {
                show: true
            },
            colors: ['#d0ccff', '#ebe9f1'],
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    inverseColors: false,
                    gradientToColors: [window.colors.solid.primary, '#ebe9f1'],
                    shadeIntensity: 1,
                    type: 'horizontal',
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100, 100, 100]
                }
            },
            markers: {
                size: 0,
                hover: {
                    size: 5
                }
            },
            xaxis: {
                labels: {
                    style: {
                        colors: '#b9b9c3',
                        fontSize: '1rem'
                    }
                },
                axisTicks: {
                    show: false
                },
                categories: data.days,
                axisBorder: {
                    show: false
                },
                tickPlacement: 'on'
            },
            yaxis: {
                tickAmount: 5,
                labels: {
                    style: {
                        colors: '#b9b9c3',
                        fontSize: '1rem'
                    },
                    formatter: function (val) {
                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                }
            },
            grid: {
                borderColor: '#e7eef7',
                padding: {
                    top: -20,
                    bottom: -10,
                    left: 20
                }
            },
            tooltip: {
                x: {show: false}
            },
            series: [
                {
                    name: financialHelper.data('current-month'),
                    data: data.monthly
                },
                {
                    name: financialHelper.data('previous-month'),
                    data: data.previous
                }
            ]
        };

        revenueChart = new ApexCharts(document.querySelector('#monthly-chart-invoices'), revenueChartOptions);
        revenueChart.render().then();

        $('#monthly-chart-invoices').data('chart', revenueChart);
    });

    $.ajax({
        url: financialHelper.data('monthly-invoice-number-chart'),
        type: "post",
        async: true,
        data: 'year=' + year.val() + '&month=' + month.val()
    }).done(function (response) {
        let data = response;
        let revenueChart;
        let revenueChartOptions = {
            chart: {
                height: 240,
                toolbar: {show: false},
                zoom: {enabled: false},
                type: 'line',
                offsetX: -10
            },
            stroke: {
                curve: 'smooth',
                dashArray: [0, 12],
                width: [4, 3]
            },
            legend: {
                show: true
            },
            colors: ['#d0ccff', '#ebe9f1'],
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    inverseColors: false,
                    gradientToColors: [window.colors.solid.primary, '#ebe9f1'],
                    shadeIntensity: 1,
                    type: 'horizontal',
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100, 100, 100]
                }
            },
            markers: {
                size: 0,
                hover: {
                    size: 5
                }
            },
            xaxis: {
                labels: {
                    style: {
                        colors: '#b9b9c3',
                        fontSize: '1rem'
                    }
                },
                axisTicks: {
                    show: false
                },
                categories: data.days,
                axisBorder: {
                    show: false
                },
                tickPlacement: 'on'
            },
            yaxis: {
                tickAmount: 5,
                labels: {
                    style: {
                        colors: '#b9b9c3',
                        fontSize: '1rem'
                    },
                    formatter: function (val) {
                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                }
            },
            grid: {
                borderColor: '#e7eef7',
                padding: {
                    top: -20,
                    bottom: -10,
                    left: 20
                }
            },
            tooltip: {
                x: {show: false}
            },
            series: [
                {
                    name: financialHelper.data('current-month'),
                    data: data.monthly
                },
                {
                    name: financialHelper.data('previous-month'),
                    data: data.previous
                }
            ]
        };

        revenueChart = new ApexCharts(document.querySelector('#monthly-chart-invoice-number'), revenueChartOptions);
        revenueChart.render().then();

        $('#monthly-chart-invoice-number').data('chart', revenueChart);
    });

    let customerChart;
    let customerChartOptions = {
        chart: {
            type: 'pie',
            height: 325,
            toolbar: {
                show: false
            }
        },
        labels: [
            financialHelper.data('gross-income-text'),
            financialHelper.data('vat-text'),
            financialHelper.data('product-cost-text'),
            financialHelper.data('payouts-text'),
            financialHelper.data('expenses-text')
        ],
        series: [
            financialHelper.data('gross-income'),
            financialHelper.data('vat'),
            financialHelper.data('product-cost'),
            financialHelper.data('payouts'),
            financialHelper.data('expenses')
        ],
        yaxis: {
            tickAmount: 5,
            labels: {
                style: {
                    colors: '#b9b9c3',
                    fontSize: '1rem'
                },
                formatter: function (val) {
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
            }
        },
        dataLabels: {
            enabled: true
        },
        legend: {show: true},
        stroke: {
            width: 4
        },
        colors: [window.colors.solid.success, window.colors.solid.warning, window.colors.solid.danger, window.colors.solid.primary, window.colors.solid.info]
    };

    customerChart = new ApexCharts(document.querySelector('#customer-chart'), customerChartOptions);
    customerChart.render().then();

    $('#customer-chart').data('chart', customerChart);
    $('#goal-overview-chart').data('chart', goalChart);
    $('#profit-overview-chart').data('chart', profitChart);

    year.on('change', onMonthYearChange_43c6855e);
    month.on('change', onMonthYearChange_43c6855e);
}

export function unloadFinancialReportPage() {
    delete window.ApexCharts;

    let incomeExpenseComparison = $('#customer-chart');
    let performanceChart = $('#goal-overview-chart');
    let profitChart = $('#profit-overview-chart');
    let yearlyComparison = $('#revenue-report-chart');
    let monthlyOrders = $('#monthly-chart-orders');
    let monthlyInvoices = $('#monthly-chart-invoices');

    $('#year').off('change', onMonthYearChange_43c6855e);
    $('#month').off('change', onMonthYearChange_43c6855e);

    if (typeof incomeExpenseComparison.data('chart') !== 'undefined') {
        incomeExpenseComparison.data('chart').destroy();
    }

    if (typeof performanceChart.data('chart') !== 'undefined') {
        performanceChart.data('chart').destroy();
    }

    if (typeof profitChart.data('chart') !== 'undefined') {
        profitChart.data('chart').destroy();
    }

    if (typeof yearlyComparison.data('chart') !== 'undefined') {
        yearlyComparison.data('chart').destroy();
    }

    if (typeof monthlyOrders.data('chart') !== 'undefined') {
        monthlyOrders.data('chart').destroy();
    }

    if (typeof monthlyInvoices.data('chart') !== 'undefined') {
        monthlyInvoices.data('chart').destroy();
    }
}